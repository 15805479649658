@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/light';

.edit-education {
  $breakpoint: 850px;
  $breakpointSmall: 700px;

  &__modal-content {
    > * + * {
      margin-top: spacing.$m;
    }
  }

  &__fieldset {
    border: 0;
    margin: 0;
    padding: spacing.$l;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$xl;
    background: light.$surface-primary-default;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$s;
    flex-direction: column;
  }

  &__fields-inner {
    display: flex;
    gap: spacing.$l;
    justify-content: space-between;

    @media (max-width: $breakpoint) {
      flex-direction: column-reverse;
    }
  }

  &__remove-button {
    display: flex;
    justify-content: flex-end;
  }

  &__row {
    display: flex;
    flex-grow: 1;
    gap: spacing.$l;

    @media (max-width: $breakpointSmall) {
      flex-direction: column;
    }

    > * {
      width: 100%;
    }
  }

  &__descriptive-header {
    margin: spacing.$m 0;
  }

  &__no-added {
    display: flex;
    align-items: center;
    margin-bottom: spacing.$xl;
  }

  &__add {
    margin-bottom: spacing.$xl;
  }

  &__order-wrapper {
    @media (max-width: $breakpoint) {
      align-self: flex-end;
    }
  }

  &__order {
    display: flex;
    gap: spacing.$s;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: spacing.$s;
  }
}
